import { gql, useQuery } from '@apollo/client';
import { Account } from '@/types/account';

/**
 * If this is updated, also update ScriptsStoryChiefComposer.php
 * @see app/Http/ViewComposers/ScriptsStoryChiefComposer.php
 * @see resources/assets/js/packages/graphql/init-data/global/account.js
 */
const ACCOUNT_QUERY = gql`
  query Account {
    account {
      __typename
      id
      parent_id
      name
      website
      company_description
      qualification_profile
      slug
      display_name
      trial_ends_at
      on_freemium
      on_generic_trial
      billing_currency
      billing_country
      onboarding_progress
      onboarding_questionnaire
      agency_logo
      owner {
        __typename
        id
        fullname
      }
      languages {
        __typename
        code
        label
        default
      }
      subscription {
        __typename
        id
        primary_stripe_price
        valid
        canceled
        can {
          __typename
          update
          downgrade_or_upgrade
        }
      }
      can {
        __typename
        destinations
        integrations
        notifications
        activities
        private_campaigns
        brand_voices
        onboarding
        power_mode
        two_factor_authentication
      }
    }
  }
`;

export default function useAccount(): Account {
  const { data } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  return data?.account || null;
}
